var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iframe-container-box container-table" },
    [
      _c(
        "rs-form",
        {
          ref: "form",
          staticClass: "query-form",
          attrs: {
            size: "small",
            "label-width": "68px",
            "label-position": "left",
            model: _vm.formQueryStation,
            inline: "",
          },
        },
        [
          _c(
            "rs-form-item",
            { attrs: { label: "岗位名称" } },
            [
              _c("rs-input", {
                staticStyle: { width: "180px" },
                attrs: { placeholder: "请输入岗位名称" },
                model: {
                  value: _vm.formQueryStation.stationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formQueryStation, "stationName", $$v)
                  },
                  expression: "formQueryStation.stationName",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "query-status", attrs: { label: "岗位状态" } },
            [
              _c(
                "rs-select",
                {
                  attrs: { "value-key": "id", placeholder: "请选择状态" },
                  model: {
                    value: _vm.formQueryStation.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formQueryStation, "status", $$v)
                    },
                    expression: "formQueryStation.status",
                  },
                },
                _vm._l(_vm.stationOptions, function (item) {
                  return _c("rs-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            [
              _c(
                "rs-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.buttonLoading,
                  },
                  on: { click: _vm.search },
                },
                [_vm._v("查询")]
              ),
              _c(
                "rs-button",
                {
                  attrs: { type: "text", size: "mini" },
                  on: { click: _vm.setClean },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c(
            "rs-table",
            {
              staticClass: "my-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.stationTableData,
                border: "",
                "tooltip-effect": "light",
                "highlight-current-row": "",
                "max-height": "700px",
              },
              on: { "row-dblclick": _vm.handleRowStationClick },
            },
            [
              _c("rs-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "left",
                  width: "70",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  label: "岗位编码",
                  prop: "stationShowCode",
                  formatter: _vm.formatValue,
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  label: "岗位名称",
                  prop: "stationName",
                  formatter: _vm.formatValue,
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  prop: "userName",
                  formatter: _vm.formatValue,
                  label: "员工姓名",
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  prop: "remark",
                  label: "岗位描述",
                  formatter: _vm.formatValue,
                  "show-overflow-tooltip": "",
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  formatter: _vm.statonFormatStatus,
                  align: "left",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "align_right marginTo10" },
            [
              _c("rs-pagination", {
                attrs: {
                  "current-page": _vm.currentStationPage,
                  "page-sizes": [1, 10, 20, 50, 100],
                  "page-size": _vm.stationPageSize,
                  layout: "prev, pager, next,sizes, jumper",
                  total: _vm.stationTotal,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }