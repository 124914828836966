var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iframe-container-box container" },
    [
      _c(
        "rs-row",
        { staticStyle: { background: "#fff", "min-height": "600px" } },
        [
          _c("rs-col", { attrs: { span: 7 } }, [
            _c("div", { staticClass: "left-box" }, [
              _c(
                "div",
                [
                  _c("rs-input", {
                    staticClass: "searchName",
                    attrs: {
                      placeholder: "搜索组织名称",
                      "suffix-icon": _vm.searchName
                        ? "rs-icon-circle-close"
                        : "",
                      clearable: !!_vm.searchName,
                    },
                    model: {
                      value: _vm.searchName,
                      callback: function ($$v) {
                        _vm.searchName = $$v
                      },
                      expression: "searchName",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "flex-div mt10" },
                    [
                      _c(
                        "rs-select",
                        {
                          staticStyle: { width: "130px" },
                          attrs: {
                            "value-key": "id",
                            placeholder: "组织状态不限",
                          },
                          model: {
                            value: _vm.searchStatus,
                            callback: function ($$v) {
                              _vm.searchStatus = $$v
                            },
                            expression: "searchStatus",
                          },
                        },
                        _vm._l(_vm.optionsStatus, function (item) {
                          return _c("rs-option", {
                            key: item.id,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _c(
                        "rs-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.handleCreate },
                        },
                        [_vm._v("添加组织")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "tree-div-box" }, [
                _c(
                  "div",
                  { staticClass: "mt10 tree-div" },
                  [
                    _c("rs-tree", {
                      ref: "organizationTree",
                      staticClass: "organization-tree filter-tree",
                      attrs: {
                        data: _vm.treeData,
                        props: _vm.defaultProps,
                        "current-node-key": _vm.selectTreeNodeKey,
                        "expand-on-click-node": false,
                        "node-key": "id",
                        "default-expand-all": "",
                        "highlight-current": "",
                        "render-content": _vm.renderContentTree,
                        "filter-node-method": _vm.filterNode,
                      },
                      on: { "node-click": _vm.handleNodeClick },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("rs-col", { attrs: { span: 17 } }, [
            !_vm.selectOrganization
              ? _c(
                  "div",
                  { staticClass: "right-box noOrg" },
                  [
                    _c("img", {
                      staticClass: "imgShow",
                      attrs: {
                        src: require("../../assets/images/no_data_org@2x.png"),
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        "您所在公司下暂无组织，可以在该公司下添加组织，如北京分公司、上海分公司等"
                      ),
                    ]),
                    _c(
                      "rs-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.handleCreate },
                      },
                      [_vm._v("添加组织")]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "right-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "organization-select" },
                      [
                        _c("span", { staticClass: "f18" }, [
                          _vm._v(_vm._s(_vm.selectOrganization.orgName)),
                        ]),
                        _c(
                          "span",
                          {
                            class: [
                              !_vm.selectOrganization.status
                                ? "status-disabled"
                                : "",
                              "status-text",
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.selectOrganization.status
                                  ? "已启用"
                                  : "已停用"
                              )
                            ),
                          ]
                        ),
                        _c(
                          "rs-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectOrganization.orgName != "",
                                expression: "selectOrganization.orgName!=''",
                              },
                            ],
                            staticStyle: { "margin-left": "15px" },
                            attrs: { size: "small", type: "text" },
                            on: { click: _vm.handleDetail },
                          },
                          [_vm._v("组织详情")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "rs-radio-group",
                      {
                        staticStyle: { margin: "14px 0" },
                        attrs: { size: "mini" },
                        on: { change: _vm.changeType },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("rs-radio-button", { attrs: { label: "member" } }, [
                          _vm._v("成员"),
                        ]),
                        _c("rs-radio-button", { attrs: { label: "station" } }, [
                          _vm._v("岗位"),
                        ]),
                      ],
                      1
                    ),
                    _vm.type == "station"
                      ? _c(
                          "rs-button",
                          {
                            staticStyle: {
                              float: "right",
                              margin: "14px 10px 14px 20px",
                            },
                            attrs: { size: "mini" },
                            on: { click: _vm.handleCreateStation },
                          },
                          [_vm._v("添加岗位")]
                        )
                      : _vm._e(),
                    _vm.type == "member"
                      ? _c("account-list", { ref: "member" })
                      : _vm._e(),
                    _vm.type == "station"
                      ? _c("station-list", { ref: "station" })
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }