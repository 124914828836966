<template>
  <div class="iframe-container-box container-table">
    <rs-form size="small" class="query-form" label-width="68px" ref="form" label-position="left" :model="formDataSelect" inline>
      <rs-form-item label="员工姓名">
        <rs-input style="width: 180px" v-model="formDataSelect.userName" placeholder="请输入员工姓名"></rs-input>
      </rs-form-item>
      <rs-form-item label="员工状态">
        <rs-select v-model="formDataSelect.status" value-key="id" placeholder="请选择状态">
          <rs-option v-for="item in options" :key="item.id" :label="item.label" :value="item.value"></rs-option>
        </rs-select>
      </rs-form-item>
      <rs-form-item label="">
        <div class="floatRight">
          <rs-button type="primary" size="mini" @click="search" :loading="buttonLoading">查询</rs-button>
          <rs-button @click="clear" class="hover-style" type="text" size="mini">重置</rs-button>
        </div>
      </rs-form-item>
    </rs-form>
    <div class="table-box">
      <rs-table
        class="my-table"
        :data="tableData"
        border
        highlight-current-row
        tooltip-effect="light"
        max-height="700px"
        @row-dblclick="handleRowClick"
        style="width: 100%;"
      >
        <rs-table-column label="序号" type="index" align="left" width="70"></rs-table-column>
        <rs-table-column label="姓名" prop="userName" :formatter="formatValue" align="left"></rs-table-column>
        <rs-table-column label="岗位" prop="stationNameList" :formatter="formatValue" show-overflow-tooltip align="left"></rs-table-column>
        <rs-table-column prop="mobile" label="电话" :formatter="formatValue" align="left"></rs-table-column>
        <rs-table-column prop="email" label="电子邮箱" :formatter="formatValue" show-overflow-tooltip align="left"></rs-table-column>
        <rs-table-column prop="status" label="状态" align="left">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.status ? '#6DD400' : '#E02020' }">{{ scope.row.status | formatStatus }}</span>
          </template>
        </rs-table-column>
      </rs-table>
      <div class="align_right marginTo10">
        <rs-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,sizes, jumper"
          :total="total"
          background
        ></rs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/utils'
import Api from '@/api/organizationApi'
export default {
  components: {},
  data() {
    return {
      createForm: {},
      rules: {
        orgName: [
          { required: true, message: '请输入组织名称', trigger: 'change' },
          { max: 20, message: '最多20个字符', trigger: 'change' }
        ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      tableData: [], // 成员列表绑定数据
      currentPage: 1,
      total: 0,
      pageSize: 10,
      options: [
        {
          value: true,
          label: '已启用'
        },
        {
          value: false,
          label: '已停用'
        },
        {
          value: null,
          label: '全部'
        }
      ],
      formDataSelect: {
        userName: '',
        status: true
      }, // 成员搜索数据表单
      buttonLoading: false,
      selectOrganization: null // 选中的组织
    }
  },
  methods: {
    // 清除搜索条件
    clear() {
      this.formDataSelect = {
        userName: '',
        status: ''
      }
    },
    search(selectOrganization) {
      this.buttonLoading = true
      this.currentPage = 1
      this.selectOrganization = selectOrganization && selectOrganization.orgSysCode ? selectOrganization : this.selectOrganization
      this.getList()
    },
    getList(selectOrganization) {
      this.selectOrganization = selectOrganization && selectOrganization.orgSysCode ? selectOrganization : this.selectOrganization
      let params = {
        orgSysCode: this.selectOrganization.orgSysCode,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        userName: this.formDataSelect.userName,
        userStatus: this.formDataSelect.status
      }
      let that = this
      Api.queryUserList(params)
        .then(res => {
          this.buttonLoading = false
          that.total = res.data.data.total
          that.tableData = []
          if (res.data.data.list.length >= 0) {
            that.tableData = res.data.data.list
          }
        })
        .catch(error => {
          this.buttonLoading = false
        })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },

    // 表格内容为空时展示-
    formatValue(row, column, cellValue, index) {
      return cellValue ? cellValue : '-'
    },
    // 行点击
    handleRowClick(row, column, event) {
      this.$router.push(`/accountManageDetail/view?id=${row.userId}`)
    }
  },
  filters: {
    formatStatus(status) {
      return status ? '已启用' : '已停用'
    }
  },
  created() {
    let _self = this
    window.addEventListener('storage', function(e) {
      if (e.key === 'editUser') {
        _self.getList()
      }
    })
  }
}
</script>
<style lang="less" scoped>
@import '../style/index.less';
</style>
