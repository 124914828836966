<template>
  <div class="iframe-container-box container-table">
    <rs-form class="query-form" size="small" label-width="68px" ref="form" label-position="left" :model="formQueryStation" inline>
      <rs-form-item label="岗位名称">
        <rs-input style="width: 180px" v-model="formQueryStation.stationName" placeholder="请输入岗位名称"></rs-input>
      </rs-form-item>
      <rs-form-item label="岗位状态" class="query-status">
        <rs-select v-model="formQueryStation.status" value-key="id" placeholder="请选择状态">
          <rs-option v-for="item in stationOptions" :key="item.id" :label="item.label" :value="item.value"></rs-option>
        </rs-select>
      </rs-form-item>
      <rs-form-item>
        <rs-button type="primary" size="mini" @click="search" :loading="buttonLoading">查询</rs-button>
        <rs-button @click="setClean" type="text" size="mini">重置</rs-button>
      </rs-form-item>
    </rs-form>
    <div class="table-box">
      <rs-table
        class="my-table"
        :data="stationTableData"
        border
        tooltip-effect="light"
        highlight-current-row
        max-height="700px"
        @row-dblclick="handleRowStationClick"
        style="width: 100%;"
      >
        <rs-table-column label="序号" type="index" align="left" width="70"></rs-table-column>
        <rs-table-column label="岗位编码" prop="stationShowCode" :formatter="formatValue" align="left"></rs-table-column>
        <rs-table-column label="岗位名称" prop="stationName" :formatter="formatValue" align="left"></rs-table-column>
        <rs-table-column prop="userName" :formatter="formatValue" label="员工姓名" align="left"></rs-table-column>
        <rs-table-column prop="remark" label="岗位描述" :formatter="formatValue" show-overflow-tooltip align="left"></rs-table-column>
        <rs-table-column prop="status" label="状态" :formatter="statonFormatStatus" align="left"></rs-table-column>
      </rs-table>
      <div class="align_right marginTo10">
        <rs-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentStationPage"
          :page-sizes="[1, 10, 20, 50, 100]"
          :page-size="stationPageSize"
          layout="prev, pager, next,sizes, jumper"
          :total="stationTotal"
          background
        ></rs-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import Api from '@/api/organizationApi'
export default {
  components: {},
  data() {
    return {
      showDialog: false,
      createForm: {},
      stationTableData: [], // 岗位列表绑定数据
      currentStationPage: 1,
      stationTotal: 0,
      stationPageSize: 10,
      stationOptions: [
        {
          value: true,
          label: '已启用'
        },
        {
          value: false,
          label: '已停用'
        }
      ],
      formQueryStation: {
        stationName: '',
        status: ''
      }, // 岗位搜索数据表单
      buttonLoading: false,
      selectOrganization: null, // 选中的组织
      stationCreateForm: {},
      stationFormRules: {
        stationName: [
          { required: true, message: '请输入岗位名称', trigger: 'change' },
          { max: 20, message: '最多20个字符', trigger: 'change' }
        ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        organizationSysCode: [{ required: true, message: '请选择所在组织', trigger: 'change' }],
        sort: [
          {
            validator: (rule, value, callback) => {
              if (value != '') {
                if (!Number.isInteger(value)) {
                  callback(new Error('请输入数字值'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    // 清除岗位搜索数据
    setClean() {
      this.formQueryStation = {
        stationName: '',
        status: ''
      }
    },
    search(selectOrganization) {
      this.currentStationPage = 1
      this.selectOrganization = selectOrganization && selectOrganization.orgSysCode ? selectOrganization : this.selectOrganization
      this.getStationList()
    },
    // 查询岗位列表
    getStationList(selectOrganization) {
      this.selectOrganization = selectOrganization && selectOrganization.orgSysCode ? selectOrganization : this.selectOrganization
      let that = this
      let params = {
        orgSysCode: this.selectOrganization.orgSysCode,
        pageNum: this.currentStationPage,
        pageSize: this.stationPageSize,
        stationName: this.formQueryStation.stationName,
        stationStatus: this.formQueryStation.status
      }
      Api.queryStationList(params)
        .then(res => {
          this.buttonLoading = false
          this.stationTotal = res.data.data.total
          that.stationTableData = []
          if (res.data.data.list.length >= 0) {
            that.stationTableData = res.data.data.list
          }
        })
        .catch(err => {
          this.buttonLoading = false
        })
    },
    // 行点击查看岗位详情
    handleRowStationClick(row, column, event) {
      this.handleQueryStation(row)
    },
    // 获取岗位详情
    handleQueryStation(row) {
      // let name = `${row.stationName} (${row.stationShowCode})`
      // this.$openDrawer({url: `/stationDetail?id=${row.stationId}`, title: name, type: 'iframe', size: '36%' })
      this.$router.push(`/stationDetail?id=${row.stationId}`)
    },
    handleSizeChange(val) {
      this.stationPageSize = val
      this.getStationList()
    },
    handleCurrentChange(val) {
      this.currentStationPage = val
      this.getStationList()
    },
    statonFormatStatus(row, column) {
      return row.status ? '已启用' : '已停用'
    },
    // 表格内容为空时展示-
    formatValue(row, column, cellValue, index) {
      return cellValue ? cellValue : '-'
    },
    // 行点击
    handleRowClick(row, column, event) {
      let name = `${row.userName} (${row.userCode})`
      this.$openDrawer2({ url: `/userDetail/view?userId=${row.userId}`, title: name, type: 'iframe', size: '62%' })
    }
  },
  created() {
    let _self = this
    window.addEventListener('storage', function(e) {
      if (e.key === 'editStation') {
        _self.getStationList()
      }
    })
  }
}
</script>
<style lang="less" scoped>
@import '../style/index.less';
</style>
