var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iframe-container-box container-table" },
    [
      _c(
        "rs-form",
        {
          ref: "form",
          staticClass: "query-form",
          attrs: {
            size: "small",
            "label-width": "68px",
            "label-position": "left",
            model: _vm.formDataSelect,
            inline: "",
          },
        },
        [
          _c(
            "rs-form-item",
            { attrs: { label: "员工姓名" } },
            [
              _c("rs-input", {
                staticStyle: { width: "180px" },
                attrs: { placeholder: "请输入员工姓名" },
                model: {
                  value: _vm.formDataSelect.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formDataSelect, "userName", $$v)
                  },
                  expression: "formDataSelect.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { attrs: { label: "员工状态" } },
            [
              _c(
                "rs-select",
                {
                  attrs: { "value-key": "id", placeholder: "请选择状态" },
                  model: {
                    value: _vm.formDataSelect.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formDataSelect, "status", $$v)
                    },
                    expression: "formDataSelect.status",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("rs-option", {
                    key: item.id,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("rs-form-item", { attrs: { label: "" } }, [
            _c(
              "div",
              { staticClass: "floatRight" },
              [
                _c(
                  "rs-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      loading: _vm.buttonLoading,
                    },
                    on: { click: _vm.search },
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "rs-button",
                  {
                    staticClass: "hover-style",
                    attrs: { type: "text", size: "mini" },
                    on: { click: _vm.clear },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c(
            "rs-table",
            {
              staticClass: "my-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
                "tooltip-effect": "light",
                "max-height": "700px",
              },
              on: { "row-dblclick": _vm.handleRowClick },
            },
            [
              _c("rs-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "left",
                  width: "70",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  label: "姓名",
                  prop: "userName",
                  formatter: _vm.formatValue,
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  label: "岗位",
                  prop: "stationNameList",
                  formatter: _vm.formatValue,
                  "show-overflow-tooltip": "",
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  prop: "mobile",
                  label: "电话",
                  formatter: _vm.formatValue,
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: {
                  prop: "email",
                  label: "电子邮箱",
                  formatter: _vm.formatValue,
                  "show-overflow-tooltip": "",
                  align: "left",
                },
              }),
              _c("rs-table-column", {
                attrs: { prop: "status", label: "状态", align: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            style: {
                              color: scope.row.status ? "#6DD400" : "#E02020",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("formatStatus")(scope.row.status))
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "align_right marginTo10" },
            [
              _c("rs-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.pageSize,
                  layout: "prev, pager, next,sizes, jumper",
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }