<template>
    <div class="iframe-container-box container">
        <rs-row style="background: #fff;min-height: 600px;">
            <rs-col :span="7">
                <div class="left-box">
                    <div>
                        <rs-input placeholder="搜索组织名称" v-model="searchName" class="searchName"
                        :suffix-icon="searchName?'rs-icon-circle-close':''" :clearable="!!searchName"></rs-input>
                        <div class="flex-div mt10">
                            <rs-select
                                v-model="searchStatus"
                                style="width: 130px;"
                                value-key="id"
                                placeholder="组织状态不限">
                                <rs-option
                                    v-for="item in optionsStatus"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value">
                                </rs-option>
                            </rs-select>
                            <rs-button size="mini" @click="handleCreate">添加组织</rs-button>
                        </div>
                    </div>
                    <div class="tree-div-box">
                        <div class="mt10 tree-div">
                            <rs-tree
                                ref="organizationTree"
                                class="organization-tree filter-tree"
                                :data="treeData"
                                :props="defaultProps"
                                :current-node-key="selectTreeNodeKey"
                                :expand-on-click-node=false
                                node-key="id"
                                default-expand-all
                                highlight-current
                                :render-content="renderContentTree"
                                :filter-node-method="filterNode"
                                @node-click="handleNodeClick">
                            </rs-tree>
                        </div>
                    </div>
                    
                </div>
            </rs-col>
            <rs-col :span="17">
                <div class="right-box noOrg" v-if="!selectOrganization">
                    <img class="imgShow" src="../../assets/images/no_data_org@2x.png">
                    <p>您所在公司下暂无组织，可以在该公司下添加组织，如北京分公司、上海分公司等</p>
                    <rs-button size="mini" type="primary" @click="handleCreate">添加组织</rs-button>
                </div>
                <div class="right-box" v-else>
                    <div class="organization-select">
                        <span class="f18">{{selectOrganization.orgName}}</span>
                        <span :class="[!selectOrganization.status?'status-disabled':'', 'status-text']">{{selectOrganization.status?'已启用':'已停用'}}</span>
                        <rs-button size="small" type="text" style="margin-left: 15px;" v-show="selectOrganization.orgName!=''" @click="handleDetail">组织详情</rs-button>
                    </div>
                    <rs-radio-group v-model="type" style="margin: 14px 0;" size="mini" @change="changeType">
                        <rs-radio-button label="member">成员</rs-radio-button>
                        <rs-radio-button label="station">岗位</rs-radio-button>
                    </rs-radio-group>
                    <rs-button size="mini" v-if="type=='station'" @click="handleCreateStation" style="float: right;margin: 14px 10px 14px 20px;">添加岗位</rs-button>
                    <account-list ref="member" v-if="type=='member'"></account-list>
                    <station-list ref="station" v-if="type=='station'"></station-list>
                </div>
            </rs-col>
        </rs-row>
    </div>
</template>

<script>
    import Utils from '@/utils/utils'
    import Api from '@/api/organizationApi'
    import accountList from './components/accountList'
    import stationList from './components/stationList'
    export default {
        components: {
            accountList,
            stationList
        },
        data(){
            return{
                optionsStatus: [
                    {label: '组织状态不限', value: ''},
                    {label: '组织已开启', value: 'true'},
                    {label: '组织已停用', value: 'false'},
                ],
                treeData: [],
                defaultProps: {
                    children: 'children',
                    label: 'orgName'
                },
                // 搜索条件
                searchName: '',
                searchStatus: '',
                selectTreeNodeKey: '',
                showDialog: false,
                type: 'member',
                formDataSelect: {
                    userName:'',
                    status:''
                }, // 成员搜索数据表单
                formQueryStation: {
                    stationName:'',
                    status:''
                }, // 岗位搜索数据表单
                buttonLoading: false, 
                selectOrganization: null, // 选中的组织
                stationCreateForm: {},
                stationFormRules: {
                    stationName: [
                        {required: true, message: '请输入岗位名称', trigger: 'change'},
                        { max: 20, message: '最多20个字符', trigger: 'change' }
                    ],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}],
                    organizationSysCode: [{required: true, message: '请选择所在组织', trigger: 'change'}],
                    sort: [
                        { validator: (rule, value, callback)=>{
                            if(value!='') {
                                if (!Number.isInteger(value)) {
                                    callback(new Error('请输入数字值'));
                                } else {
                                    callback();
                                }
                            } else {
                                callback();
                            }
                        }, trigger: 'change' }
                    ]
                },
            }
        },
        methods:{
            // tab切换
            handleClick(val) {},
            //过滤树
            filterNode(value, data) {
                console.log(data.orgName, data.orgName.indexOf(value) !== -1, 'data,')
                if (!value) return true;
                return data.orgName.indexOf(value) !== -1;
            },
            handleNodeClick(val){
                this.selectOrganization = val
                this.selectTreeNodeKey = val.id
                this.search()
            },
            // 查询树形组织数据
            handleQueryTreeData(type) {
                let params = {}
                if(this.searchStatus) {
                    params = {
                        // "orgName": this.searchName,
                        "status": this.searchStatus
                    }
                }
                Api.queryOrganizationTree(params).then(res=>{
                    this.treeData = res.data.data?res.data.data:[]
                    let result = []
                    if(this.searchName!='') {
                        this.$nextTick(()=>{
                            this.$refs.organizationTree.filter(this.searchName);
                        })
                    }
                    if(this.treeData.length>0&&!type) {
                        if(!this.selectOrganization) {
                            this.selectTreeNodeKey = res.data.data[0].id
                            this.handleNodeClick(this.treeData[0])
                        } else {
                            this.selectTreeNodeKey = this.selectOrganization.id
                        }
                        this.$nextTick(()=>{
                            this.$refs.organizationTree.setCurrentKey(this.selectTreeNodeKey)
                            this.selectOrganization = this.$refs.organizationTree.getCurrentNode()
                        })
                    }
                    
                }).catch(err=>{})
            },
            // 自定义树形数据样式结构
            renderContentTree(h, { node, data, store }) {
                let imgClass = data.parentOrgSysCode == '0' ? 'org-png' :'file-png'
                let style = {
                    display: this.searchStatus!== 'false' ? 'inline-block' : 'none'
                }
                return (
                    <div class="custom-tree-node" id="tree">
                        <span class="flex1 tree-name" title={node.label}>
                            <span class={imgClass}></span>
                            <span class="tree-name-text">{node.label}</span>
                        </span>
                        <span style={style}>
                            <span class="flex1 visible" on-click={ () => this.showSortButton(node, data, store) }>
                                <i class="rs-icon-more"></i>
                            </span>
                            <div class="button-div" data-id={data.id}>
                                <div class="button-sort-text" on-click={ () => this.upSort(node, data) } type="text">上移</div>
                                <div class="button-sort-text" on-click={ () => this.downSort(node, data, store) } type="text">下移</div>
                            </div>
                        </span> 
                    </div>)
            },
            // dom元素延时消失监听

            // 下移下移按钮显示
            showSortButton(node, data, store) {
                event.stopPropagation();
                // .dataset.id
                [].slice.call(document.querySelectorAll('#tree .button-div')).forEach(el=>{
                    console.log(el.dataset.id, data.id, 'el')
                    if(data.id == el.dataset.id) {
                        el.style.display = 'inline-block'
                        el.addEventListener('mouseleave', ()=>{
                            setTimeout(()=>{
                                el.style.display = 'none'
                            }, 300)
                        })
                        el.parentNode.addEventListener('mouseleave', ()=>{
                            setTimeout(()=>{
                                el.style.display = 'none'
                            }, 300)
                        })
                    } else {
                        el.style.display = 'none'
                    }
                })
            },
            // 上移
            upSort(node, data) {
                event.stopPropagation();
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const cIndex = children.findIndex(d => d.id === data.id)
                if (parent.level === 0 && cIndex === 0) { 
                    this.$message.error('已到顶！')
                    return
                } else if (parent.level !== 0 && cIndex === 0) { //不同父节点中移动
                    this.$message.error('已到顶！')
                } else if ((parent.level === 0 && cIndex !== 0) || (parent.level !== 0 && cIndex !== 0)) {
                    const tempChildrenNodex1 = children[cIndex - 1]
                    const tempChildrenNodex2 = children[cIndex]
                    this.$set(children, cIndex - 1, tempChildrenNodex2)
                    this.$set(children, cIndex, tempChildrenNodex1)
                    let sortList = children.map((item, index)=>{
                        return {
                            id: item.id,
                            sort: index
                        }
                    })
                    this.setChange(sortList)
                }
            },
            // 下移
            downSort(node, data, store) {
                event.stopPropagation();
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const cIndex = children.findIndex(d => d.id === data.id)
                const cLength = children.length - 1 // 最边上的节点
                const allLevel = store.data.length - 1 // 树的深度
                if (parent.level === allLevel && cIndex === cLength) { // 最最末的节点
                    return
                } else if (parent.level !== allLevel && cIndex === cLength) { //父节点不同
                    this.$message.error('已到底！')
                } else if ((parent.level === allLevel && cIndex !== cLength) || (parent.level !== allLevel && cIndex !== cLength)) { // 父节点相同
                    const tempChildrenNodex1 = children[cIndex + 1]
                    const tempChildrenNodex2 = children[cIndex]
                    this.$set(children, cIndex + 1, tempChildrenNodex2)
                    this.$set(children, cIndex, tempChildrenNodex1)
                    let sortList = children.map((item, index)=>{
                        return {
                            id: item.id,
                            sort: index
                        }
                    })
                    console.log(children, sortList, 'sortList')
                    this.setChange(sortList)
                }
            },
            // 上下移动请求API
            setChange(data) {
                let params = {
                    organizationMoveSortListVoList: data
                }
                Api.setSort(params).then(res=>{
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.handleQueryTreeData('status')
                }).catch(err=>{})
            },
            search(){
                this.$nextTick(()=>{
                    this.$refs[this.type].search(this.selectOrganization)
                })
                
            },
            // 切换成员和岗位type
            changeType(val) {
                if(val === 'member') {
                    this.$refs['member'].getList(this.selectOrganization)
                } else {
                    this.$refs['station'].getStationList(this.selectOrganization)
                }
            },
            // 添加组织
            handleCreate() {
                this.$router.push(`/organizationEdit`)
            },
            // 组织详情查看
            handleDetail() {
                this.$router.push(`/organizationDetail?id=${this.selectOrganization.id}`)
            },
            // 岗位新增
            handleCreateStation() {
                this.$router.push(`/stationEdit`)
            },
        },
        watch: {
            searchName(val) {
                this.$refs.organizationTree.filter(val);
            },
            searchStatus(val) {
                this.handleQueryTreeData('status')
                // this.$refs.organizationTree.filter(val);
            },
        },
        created(){
            this.handleQueryTreeData()
            let _self = this
            window.addEventListener("storage", function (e) {
                if(e.key === 'editOrganization') {
                    _self.handleQueryTreeData()
                }
            });
        }
    }
</script>

<style lang="less" scoped>
@import './style/index.less';
</style>
    